  .container {
    flex-direction: column;
    justify-content: center;
  }
  .image {
    width: 280px;
    /* height: auto; */
    /* height: 259.84px; */
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .image:hover {
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .unordered {
    display: block;
    padding: 0rem 0.5rem;
    margin: 0;
    margin-left: 1rem;
  }
  .listItem {
    margin: 1rem;
  }

  .modalTitle{
    font-weight: bolder;
  }
  .modalImage {
    margin-top: 2rem;
    width: 100%;
  }
  
@media screen and (min-width: 849px){
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    width: 320px;
    height: auto;
  }
  
  .imageContainer {
    display: block;
    width: auto;
  }
  
  .title {
    background-color: rgb(206, 206, 206);
    margin: 0;
    padding: 2rem;
    width: 100%;
    font-size: 1.5rem;
    font-weight: bolder;
  }
  .options {
    padding: 2rem;
    display: block;
  }
  .optionHeading {
    margin-top: 1.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  button {
    background-color: rgb(206, 206, 206);
    border: 0px;
    padding: 1rem;
  }
  
  .item {
    display: block;
    width: 338px;
    margin-bottom: 1rem;
    cursor: pointer;
    border-radius: 10px;
  }

  
  
  
}