.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./keyboard.jpg");
  background-size: cover;
  background-attachment: fixed;

  color: #ffffff;
}

.text {
  padding: 6rem 2rem 2rem 2rem;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  
}
.point1 {
  font-size: 3rem;
  font-weight: bolder;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
  padding: 1rem 0 0.25rem 0;
  border-radius: 6px;
}

@media only screen and (max-width: 600px) {
  .hero {
    background: 
      url("./keyboard.jpg");
    background-size: cover;
    /* background-attachment: fixed; */
    /* max-height: 10%; */
    /* max-width: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    margin: 2rem auto;
    font-size: 3rem;
  }
  .point1 {
    font-size: 2rem;
    font-weight: bolder;
  }
}
