
.container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin: 0 3rem;
}

.content {
  flex-grow: 1;
}

.gridContainer {
  display: grid;
  grid-gap: 1.5rem;
  margin: auto auto;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.gridItem {
  display: flex;
  justify-content: left;
  align-items: center;
  border: 1px solid lightgrey;
  height: 100%;
}

.icons {
  margin: 1rem;
  width: 50px;
  max-height: 50px;
  filter: invert(76%) sepia(45%) saturate(6339%) hue-rotate(2deg)
    brightness(104%) contrast(105%);
}

.serviceItem {
  margin: 1rem 1rem 1rem 0;
  text-align: left;
}

.link {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.link:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.gridItem:hover {
  box-shadow: 0 0 0.5rem lightgrey;
  position: relative;
  bottom: 1px;
  cursor: pointer;
}

.gift {
  margin-top: 2rem;
  text-align: right;
  color: darkgreen;
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 0;
  }
  .gridContainer {
    display: block;
    margin: 0;
  }
  .gridItem {
    margin: 0.5rem;
  }
  .icons {
    margin: 1rem;
    width: 30px;
    max-height: 26px;
  }
}
