.message {
  font-size: 1rem;
  padding: 1rem;
  font-weight: bolder;
}

@media only screen and (max-width: 600px) {
  .messsage{
    font-size: 2rem;
    font-weight: bolder;
  }
}