.hamburger {
  display: none;
}

.logo {
  display: none;
}

@media only screen and (max-width: 600px) {
  .hamburger {
    width: 40px;
    height: auto;
    display: block;
    background-color: white;
    color: white;
    margin: 0.75rem;
    padding: 3px;
    border: 1px solid black;
  }
  .logo {
    display: block;
    width: 45px;
    height: auto;
    margin: 0.75rem;
    padding: 5px;
    background-color: white;
  }
  .nav {
    box-shadow: -10px 0px 20px;
    display: flex;
    justify-content: center;
    align-items: top;
    flex-direction: row;
    padding: 2rem;
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    background-color: rgba(3, 169, 244, 0.95);
    width: 80%;
    height: 100vh;
  }
  .logoInWindow {
    /* display: block; */
    width: 60px;
    height: 60px;
    background-color: white;
    /* height: auto;
    margin: 1.5rem auto; */
  }
  .navItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    /* margin: 0;
    padding: 1rem; */
    list-style: none;
    /* position: absolute;
    top: 8rem; */
  }
  .navItem {
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    font-size: 1.5rem;
    margin: .5rem;
    text-decoration: none;
  }
}
