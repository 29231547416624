.buttonContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}
.datePicker {
  display: none;
}

@media only screen and (min-width: 600px) {
 
  .noDatePicker {
    display: none;
  }

  /* .datePicker:first-of-type {
    padding: .5rem 0;
  } */
  .datePicker {
    display: block;
    background-color: white;
    width: 100%;
    border-radius: 0.25rem;
    font-weight: bold;
    padding: 0.5rem;
    margin: 1rem 0rem;
    cursor: pointer;
  }


  .interest {
    width: 70%;
  }

  .buttonContainer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}


@media only screen and (min-width: 850px) {
  .datePicker {
    background-color: white;
    width: 100%;
    border-radius: 0.25rem;
    font-weight: bold;
    padding: 0.5rem;
    margin: 1rem 0rem;
    cursor: pointer;
  }

  .interest {
    width: 70%;
  }

  .buttonContainer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}