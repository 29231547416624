.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.7rem;
}

.gridItem {
  display: flex;
  justify-content: left;
  align-items: center;
  border: 1px solid lightgrey;
  height: 100%;
}



.gridItem:hover {
  box-shadow: 0 0 0.5rem lightgrey;
  position: relative;
  bottom: 1px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .gridContainer {
    margin-top: 4rem;
    display: block;
  }
  .gridItem {
    margin: 0.5rem;
  }
 
}
