.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 2rem 1rem;
  margin-top: 6rem;
  padding: 1.7rem 1rem;
  background-color: #33a5f2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.image {
  border-radius: 50%;
  width: 100px;
}

.headers {
  display: block;
  margin: 1rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: white;
}

.header {
  font-size: 1rem;
  padding: 0.25rem;
  font-weight: bolder;
  /* color: rgb(192, 168, 214); */
  color: black;
  margin: 0.5rem;
  text-align: center;
}


@media screen and (min-width: 850px) {
  .container {
    background-color: #33A5F2;
    color: white;
    margin: 2rem 6rem;
    margin-top: 7rem;
    padding: 2rem;
    border: 1px solid rgb(221, 203, 255);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .image {
    border-radius: 50%;
    width: 120px;
  }

  .imageContainer {
    display: block;
    width: 25%;
  }

  .headers {
    display: block;
    margin: 1rem;
    width: 100%;
    border-radius: 0.25rem;
    background-color: white;
  }

  .header {
    font-size: 1.6rem;
    font-weight: bolder;
    /* color: rgb(192, 168, 214); */
    color: black;
    margin: 0.5rem;
  }
}
