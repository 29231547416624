.niceButton {
  background-color: #FF9801;
  border-radius: 0.25rem;
  border: 0px;
  font-weight: bold;
  padding: 1rem;
  cursor: pointer;
}

.niceButton:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
