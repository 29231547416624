.anchor {
  display: block;
  position: relative;
  top: -130px;
}



.heading1 {
    margin: 1rem;
}
.subHeaders {
  padding: 0.5rem;
  
}
.paragraph1 {
  font-size: 1.25rem;
  font-weight: 200;
  margin: 0 2.5rem;
}
.cardsContainer {
  display: grid;
  margin: 1.5rem;
  grid-gap: 20px;
  grid-template-columns: auto 45% 45% auto;
  grid-template-rows: auto auto;
  color: #ff9800;
}
.fa {
  font-size: 2rem;
  margin: 1rem;
  color: #ff9800;
}
.goodItem {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  font-size: 1rem;
}
.text {
  margin-top: 0.1rem;
  font-size: 1rem;
  color: black;
}

.card1 {
  display: flex;
  align-items: center;
  padding: 0.7rem;
  grid-column-start: 2;
  border: 1px solid lightgrey;
}
.card2 {
  display: flex;
  align-items: center;
  padding: 0.7rem;
  grid-column-start: 3;
  border: 1px solid lightgrey;
}
.card3 {
  display: flex;
  align-items: center;
  padding: 0.7rem;
  grid-column-start: 2;
  grid-row: 2;
  border: 1px solid lightgrey;
}
.card4 {
  display: flex;
  align-items: center;
  padding: 0.7rem;
  grid-column-start: 3;
  grid-row: 2;
  border: 1px solid lightgrey;
}

.card1:hover {
  box-shadow: 0 0 0.5rem lightgrey;
  position: relative;
  bottom: 1px;
}

.card2:hover {
  box-shadow: 0 0 0.5rem lightgrey;
  position: relative;
  bottom: 1px;
}
.card3:hover {
  box-shadow: 0 0 0.5rem lightgrey;
  position: relative;
  bottom: 1px;
}
.card4:hover {
  box-shadow: 0 0 0.5rem lightgrey;
  position: relative;
  bottom: 1px;
}

.dream {
  background-color: #33a5f2;
  width: 100%;
  margin: 2rem 0;
  padding: 2rem 0;
  font-size: 2.5rem;
  color: white;
}

@media only screen and (max-width: 600px) {
  .cardsContainer {
    display: block;
    margin: 1rem;
  }

  .card1 {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }
  .card2 {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }
  .card3 {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }
  .card4 {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }
  .goodItem {
    text-align: center;
  }
}
