.container {
  background-color: rgba(0,0,0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 85%;
  width: 85%;
  position: relative;
  z-index: 1001;
  overflow-y: auto;
  padding: 2rem;
}

.closeButton {
  position: absolute;
  font-size: 2rem;
  top: 1rem;
  right: 1.5rem;
  z-index: 1002;
  cursor: pointer;
  border-radius: 50%;
}

.hide {
  display: none;
}

@media only screen and (max-width: 850px) {
  .closeButton {
    position: absolute;
    font-size: 2rem;
    top: .5rem;
    right: .5rem;
    z-index: 1002;
  }
}