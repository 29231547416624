.footer {
  margin: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

 
}
.themeDev {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  
}
.badge {
  vertical-align: middle;
  margin: 0;
}
.themeDevText {
  margin-left: 1rem;
}

@media only screen and (max-width: 600px) {
  .themeDev {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    
  }
  .badge {
    width: 45px;
  }
  .themeDevText {
    text-align: left;
    font-size: 0.8rem;
  }
}
