.sectionContainer {
  background-color: rgb(245, 250, 253);
  padding: 2rem;
}

.imageContainer {
  display: grid;
  grid-template-columns: auto 30% 35% 30% auto;
  align-items: end;
  padding: 1rem;
  margin-top: 4rem;
}
.gridpic1 {
  grid-column: 2 / 3;
  position: relative;
  left: 25%;
  box-shadow: -20px 20px 1.5rem lightgrey;
}
.gridpic2 {
  grid-column: 3 / 4;
  z-index: 2;
  box-shadow: 20px 20px 1.5rem lightgrey;
}
.gridpic3 {
  grid-column: 4 / 5;
  position: relative;
  right: 25%;
  box-shadow: 20px 20px 1.5rem lightgrey;
}
.pic {
  width: 100%;
}

/* @media only screen and (max-width: 600px) {
  .imageContainer {
    display: grid;
    grid-template-columns: auto 30% 35% 30% auto;
    align-items: end;
    margin-top: 4rem;
  }
} */
