.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 5;
}

.nav {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.navNotTop {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 10px 20px;
  /* .navItem {
    color: black;
  }
  .logo {
    background-color: white;
    border-radius: 10%;
  } */
}
.logo {
  margin: 0.5rem;
  width: 150px;
  height: 80px;
}

.navList {
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
}

.navItem {
  margin: 0;
  display: block;
  
}

.navItem a {
  font-size: 0.9rem;
  margin-right: 1.3rem;
  font-weight: bolder;
  color: white;
}

.navItem:hover {
  color: grey;
  text-decoration: none;
}

.activeNavItem {
  color: #333333;
}
@media only screen and (max-width: 600px) {
  .header {
    background-color: #33A5F2;
    box-shadow: 0px 10px 20px;
  }
  .nav {
    display: none;
  }
  .navNotTop {
    display: none;
  }
  .logo {
    width: 70px;
    height: auto;
  }
  .navItem a {
      font-size: 0.4rem;
      margin-right: 0.3rem;
    }
}

