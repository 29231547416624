.sectionContainer {
  text-align: center;
  margin-bottom: 4rem;
  
}

h1 {
  margin: 3rem 1.5rem;
}
h2 {
  margin: 3rem 1.5rem;
}
h3 {
  margin: 3rem 1.5rem;
}
h4 {
  margin: 3rem 1.5rem;
}