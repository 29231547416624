.container {
  margin: 0 6rem;
  border: 1px solid grey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.image {
  border-radius: 50%;
  width: 120px;
}

.imageContainer {
  display: block;
  width: 25%;
}

.title {
  background-color: rgb(206, 206, 206);
  margin: 0;
  padding: 2rem;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bolder;
}
.options {
  display: block;
}
.option {
  position: relative;
}
.optionHeading {
  margin-top: 1.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.optionGift {
  font-size: 1.5rem;
  font-weight: bold;
  color: darkgreen;
}

.optionGiftConfirm {
  font-size: 1rem;
  font-weight: bold;
  color: darkgreen;
  text-align: right;
}

.terms {
  font-style: italic;
}

.select {
  font-size: 1rem;
  width: 100%;
}

.button {
  position: relative;
  display: flex;
  justify-content: center;
}

.modal {
  position: relative;
  z-index: 10001;
}

.modalButton {
  text-align: right;
}



@media only screen and (min-width: 850px) {

  .select {
    font-size: 1.5rem;
    width: 100%;
  }
  
  .button {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .optionHeading {
    margin-right: 10rem;
  }
}