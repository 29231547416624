.anchor {
  display: block;
  position: relative;
  top: -130px;
}

.sectionContainer {
  background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  background-attachment: fixed;
  text-align: center;
  padding: 2rem;
}
